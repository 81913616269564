<template>
    <f7-app color="blue" :params="f7params">
        <!-- Status bar overlay for fullscreen mode-->
        <f7-statusbar :class="userLogged ? 'statusbar-dark' : 'statusbar-light'"></f7-statusbar>


        <!-- Views/Tabs container -->
        <f7-views tabs class="safe-area">
            <!-- Tabbar for switching views-tabs -->
            <f7-toolbar tabbar labels bottom>
                <f7-link v-if="masterDetailMode"
                         tab-link
                         popup-open="#register-popup"
                         icon-ios="f7:tickets"
                         icon-aurora="f7:tickets"
                         icon-md="f7:tickets"
                         :text="homeViewLabel">
                </f7-link>
                <f7-link v-if="!masterDetailMode"
                         tab-link="#view-home"
                         tab-link-active
                         icon-ios="f7:tickets"
                         icon-aurora="f7:tickets"
                         icon-md="f7:tickets"
                         :text="homeViewLabel">
                </f7-link>

                <f7-link v-if="codesEnabled" popup-open="#generate-codes-popup" tab-link icon-ios="f7:barcode_viewfinder" icon-aurora="f7:barcode_viewfinder" icon-md="f7:barcode_viewfinder" text="Kody rabatowe"></f7-link>
                <f7-link v-if="importEnabled" popup-open="#import-popup" tab-link icon-ios="f7:arrow_up_doc" icon-aurora="f7:arrow_up_doc" icon-md="f7:arrow_up_doc" text="Import"></f7-link>

                <f7-link id="view-stays-tab" tab-link="#view-stays" :tab-link-active="masterDetailMode">
                    <f7-icon ios="f7:list" aurora="f7:list" md="material:list">
                        <f7-badge color="red" v-if="unpaidStays">{{unpaidStays}}</f7-badge>
                    </f7-icon>
                    <span class="tabbar-label">Pobyty i wpłaty</span>
                </f7-link>
                <f7-link tab-link="#view-reports" icon-ios="f7:document_text" icon-aurora="f7:document_text" icon-md="f7:document_text" text="Raporty"></f7-link>
                <f7-link tab-link popup-open="#user-popup" icon-ios="f7:person_round" icon-aurora="f7:person_round" icon-md="f7:person_round" text="Konto"></f7-link>
            </f7-toolbar>

            <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
            <f7-view id="view-home" name="home" tab :tab-active="!masterDetailMode"  url="/"></f7-view>
            <f7-view id="generate-codes" name="import" tab  url="/codes/"></f7-view>
            <f7-view id="view-import" name="import" tab  url="/import/"></f7-view>
            <f7-view id="view-stays" name="stays" tab  url="/stays/"></f7-view>
            <f7-view id="view-reports" name="reports" tab url="/reports/"></f7-view>


        </f7-views>


        <!-- Popup -->
        <f7-popup id="register-popup">
            <f7-view url="/"></f7-view>
        </f7-popup>

        <f7-popup id="import-popup">
            <f7-view url="/import"></f7-view>
        </f7-popup>

        <f7-popup id="generate-codes-popup">
            <f7-view url="/codes"></f7-view>
        </f7-popup>


        <f7-popup id="user-popup" v-if="payload">
            <f7-view>
                <f7-page>
                    <f7-navbar title="Moje konto">
                        <f7-nav-right>
                            <f7-link popup-close>Zamknij</f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <f7-block-header>Zmiana hasła</f7-block-header>
                    <form @submit="changePassword">
                        <f7-list inset no-hairlines-md>
                            <input v-show="false" type="text" name="username" :value="payload.login" autocomplete="username">
                            <f7-list-input
                                    v-if="requireOldPassword"
                                    label="Aktualne hasło"
                                    type="password"
                                    autocomplete="current-password"
                                    placeholder="Aktualne hasło"
                                    :value="newPassword.currentPassword"
                                    @input="newPassword.currentPassword = $event.target.value"
                                    required
                            ></f7-list-input>
                            <f7-list-input
                                    label="Nowe hasło"
                                    type="password"
                                    autocomplete="new-password"
                                    placeholder="Nowe hasło"
                                    :value="newPassword.newPassword"
                                    @input="newPassword.newPassword = $event.target.value"
                                    :error-message="newPasswordErrorMessage"
                                    :error-message-force="newPasswordValidator"
                                    required
                            ></f7-list-input>
                            <f7-list-input
                                    label="Powtórz nowe hasło"
                                    type="password"
                                    autocomplete="new-password"
                                    placeholder="Powtórz nowe hasło"
                                    :value="newPassword.newPasswordConfirmation"
                                    @input="newPassword.newPasswordConfirmation = $event.target.value"
                                    :error-message="newPasswordConfirmationErrorMessage"
                                    :error-message-force="newPasswordConfirmationValidator"
                                    required
                            ></f7-list-input>
                            <f7-list-button @click="changePassword" title="Zmień hasło"></f7-list-button>
                        </f7-list>
                    </form>

                    <f7-list inset no-hairlines-md>
                        <f7-list-button title="Wyloguj się" @click="logout"></f7-list-button>
                    </f7-list>
                </f7-page>
            </f7-view>
        </f7-popup>

        <f7-login-screen id="my-login-screen" :opened="!userLogged">
            <f7-view>
                <f7-page login-screen>
                    <f7-login-screen-title><img :src="logo" height="35"></f7-login-screen-title>
                    <form @submit="login" v-if="!loginScreen.resetPassword">
                        <f7-list>
                            <f7-list-input
                                    label="E-mail"
                                    type="email"
                                    validate
                                    required
                                    name="login"
                                    autocomplete="username"
                                    placeholder="Podaj e-mail"
                                    :value="user.login"
                                    @input="user.login = $event.target.value"
                            ></f7-list-input>
                            <f7-list-input
                                    label="Hasło"
                                    type="password"
                                    autocomplete="current-password"
                                    name="password"
                                    validate
                                    required
                                    placeholder="Podaj hasło"
                                    :value="user.password"
                                    @input="user.password = $event.target.value"
                            ></f7-list-input>
                        </f7-list>
                        <f7-list inset>
                            <f7-button type="submit">Zaloguj się</f7-button>
                            <f7-button @click="loginScreen.resetPassword = 1">Nie pamiętam hasła</f7-button>
                        </f7-list>
                    </form>

                    <form @submit="sendPin" v-if="loginScreen.resetPassword && !showPinPad">
                        <f7-list>
                            <f7-list-input
                                    label="E-mail"
                                    type="text"
                                    name="login"
                                    placeholder="Podaj e-mail"
                                    :value="user.login"
                                    @input="user.login = $event.target.value"
                            ></f7-list-input>
                        </f7-list>
                        <f7-list inset>
                            <f7-button type="submit">Wyślij PIN</f7-button>
                            <f7-button @click="loginScreen.resetPassword = 0">Powrót</f7-button>
                        </f7-list>
                    </form>

                    <form v-if="loginScreen.resetPassword && showPinPad">
                        <h3 style="text-align: center">{{ pinAlert }}</h3>
                        <f7-list>
                            <f7-list-input
                                    :input-id="elementId('pin-keypad')"
                                    class="pin-keypad"
                                    name="login"
                                    type="numpad"
                                    placeholder="_____"
                            ></f7-list-input>
                            <f7-list inset>
                                <f7-button @click="logout()">Powrót</f7-button>
                            </f7-list>
                        </f7-list>
                    </form>
                </f7-page>
            </f7-view>
        </f7-login-screen>
    </f7-app>
</template>
<script>

    import routes from '../js/routes.js';
    import logo from '../assets/logo-light.svg'
    import Framework7 from '../../build/framework7.esm.bundle.js';

    export default {
        data() {
            let theme = Framework7.device.desktop ? 'aurora' : 'auto';
            return {
                id: 1,
                logo: logo,
                pinAlert: 'Podaj PIN otrzymany na skrzynkę e-mail',
                user: {
                    pin: '_____',
                    login: null,
                    password: null,
                },
                newPassword: {
                    currentPassword: '',
                    newPassword: '',
                    newPasswordConfirmation: ''
                },
                loginScreen: {
                    resetPassword: false
                },
                masterDetailMode: false,
                // Framework7 Parameters
                f7params: {
                    name: 'Travelhost', // App name
                    theme: theme,
                    language: 'pl',
                    calendar: {
                        monthNames: [
                            'Styczeń',
                            'Luty',
                            'Marzec',
                            'Kwiecień',
                            'Maj',
                            'Czerwiec',
                            'Lipiec',
                            'Sierpień',
                            'Wrzesień',
                            'Październik',
                            'Listopad',
                            'Grudzień'
                        ],
                        dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
                        dayNamesShort: ['Nie', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'So']
                    },
                    dialog: {
                        buttonCancel: 'Anuluj',

                    },
                    // App routes
                    routes: routes,
                    view: {
                        //pushState: true,
                        masterDetailBreakpoint: 800,
                    },
                    // Register service worker
                    serviceWorker: {
                        path: '/service-worker.js',
                    }
                },
            }
        },
        computed: {
            userLogged() {
                return this.$store.getters.userLogged;
            },
            showPinPad() {
                if(this.$store.getters.pinCreated) {
                    this.$nextTick(function () {
                        this.createPinKeypad();
                    });
                }
                return this.$store.getters.pinCreated;
            },
            token() {
                return this.$store.state.user.token;
            },
            payload() {
                return this.$store.state.user.payload;
            },
            stays() {
                return this.$store.state.stays.items ? this.$store.state.stays.items : [];
            },
            unpaidStays() {
                return this.stays.filter( ({paid}) => !!!paid).length;
            },

            requireOldPassword() {
                if(this.payload.noValidationPasswordChange) {
                    return false;
                }
                return true;
            },

            newPasswordErrorMessage() {
                if(this.newPassword.newPassword==='') return '';
                return this.newPassword.newPassword>=10 ? 'Hasło musi zawierać jedną liczbę i znak' : 'Hasło musi składać się z minimum 10 znaków';
            },
            newPasswordValidator() {
                let newPassword = this.newPassword.newPassword;
                return !(newPassword.length>=10 && newPassword.match(/\d/) && newPassword.match(/[a-zA-Z]/));
            },
            newPasswordConfirmationErrorMessage() {
                return this.newPasswordConfirmationValidator ? 'Powtórzone hasło nie jest identyczne': '';
            },
            newPasswordConfirmationValidator() {
                return this.newPassword.newPasswordConfirmation !== this.newPassword.newPassword;
            },
            accommodation: function () {
                return this.$store.getters.accommodation;
            },
            homeViewLabel: function ()
            {
                if(!this.accommodation || this.accommodation.mode!=='front_desk_app') {
                    return 'Pobierz';
                }

                return 'Rejestruj';
            },
            codesEnabled: function () {
                return this.masterDetailMode &&
                    this.$store.getters.accommodations &&
                    this.$store.getters.accommodations.filter(accommodation => accommodation.coupons===true).length > 0
            },
            importEnabled: function () {
                return this.masterDetailMode &&
                    this.$store.getters.accommodations &&
                    this.$store.getters.accommodations.filter(accommodation => accommodation.import===true).length > 0
            }
        },
        methods: {
            login(event) {
                event.preventDefault();
                this.$store.dispatch('login', {data: this.user, f7: this.$f7});
            },
            sendPin(event) {
                event.preventDefault();
                this.$store.dispatch('sendPin', {data: this.user, f7: this.$f7});
            },
            logout() {
                this.$store.dispatch('logout');
            },
            changePassword() {

                if(this.newPasswordValidator || this.newPasswordConfirmationValidator) return;

                this.$store.dispatch('changePassword', {data: this.newPassword, f7: this.$f7}).then(() => {
                    this.newPassword.currentPassword = '';
                    this.newPassword.newPassword = '';
                    this.newPassword.newPasswordConfirmation = '';
                });
            },
            createPinKeypad() {
                let keypad = this.$f7.keypad.create({
                    inputEl: '#' + this.elementId('pin-keypad'),
                    toolbarCloseText: 'Gotowe',
                    valueMaxLength: 5,
                    value: '',
                    dotButton: false,
                    routableModals: false,
                    formatValue: function (value) {
                        value = value.toString();
                        return value + ('_____').substring(0, 5 - value.length);
                    },
                    on: {change: this.loginByPin },
                });
                keypad.open();

            },
            loginByPin(keypad, value) {
                let $f7 = this.$f7;
                value = value.toString();
                this.user.pin = value;
                if (value.length===5) {
                    keypad.close();
                    this.$store.dispatch('loginByPin', {data: this.user, f7: this.$f7})
                    .then( () => {
                        this.loginScreen.resetPassword = false;
                        $f7.popup.open('#user-popup');
                    })
                    .catch( (message) => {
                        keypad.close();
                        keypad.setValue('');
                        keypad.open();
                        this.user.pin = '';
                        this.pinAlert = message;
                    });
                }
            },
            elementId(...parts) {
                parts.push(this.id);
                return parts.join('__');
            }
        },
        mounted() {
            this.id = this._uid;

            this.masterDetailMode = this.$f7.width >= 800;
            if(this.masterDetailMode) {
                this.$$('#view-stays-tab').trigger('click');
            }

            if(this.$f7.device==='desktop') {
                this.$f7.theme = 'aurora';
            }
        }
    }
</script>
<style>
    .statusbar-dark {
        background: #0C3852;
    }
    .statusbar-light {
        background: #fff;
    }
    .pin-keypad input {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        box-sizing: border-box;
        border: none;
        background: none;
        border-radius: 0 0 0 0;
        box-shadow: none;
        display: block;
        padding: 0 0 0 5px;
        margin: 0;
        width: 100%;
        height: 43px !important;
        color: #000;
        font-family: "Lucida Console", Monaco, monospace !important;
        letter-spacing: 10px;
        text-align: center;
        font-size: 30px !important;
        font-weight: 300;
    }
</style>
